var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "article-card align-center",
      class: _vm.size,
      attrs: {
        elevation: "2",
        to: {
          name: _vm.article.articleType.name,
          params: { pageName: _vm.article.slug }
        }
      }
    },
    [
      _vm.isAuthenticated
        ? _c(
            "div",
            { staticClass: "action-icons" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "icon-wraper",
                  attrs: { icon: "", color: "secondary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.toggleFav(_vm.article.articleId)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "secondary" } }, [
                    _vm._v(
                      _vm._s(
                        _vm.articleStatus(_vm.article.articleId)
                          ? "$heartfull"
                          : "$heart"
                      )
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-img", {
        attrs: {
          src: _vm.refImageUrl,
          alt: _vm.refImageAlt,
          height: _vm.imgHeight,
          "max-height": _vm.imgHeight,
          onerror: "this.src='/no-icon.png'"
        }
      }),
      _c(
        "v-card-subtitle",
        {
          staticClass: "article-type w-100 py-1",
          class: _vm.courseClass?.toLowerCase()
        },
        [_vm._v(" " + _vm._s(_vm.course) + " ")]
      ),
      _c("v-card-title", { staticClass: "w-100 py-0" }, [
        _c("div", [_vm._v(_vm._s(_vm.article.title))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }